import React from 'react';
import block from 'bem-cn-lite';
import './PrivacyPolicy.scss';
import {Layout} from '../../components/layout';
import { useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';
import { Button, Icon } from '@gravity-ui/uikit';
import { ChevronLeft } from '@gravity-ui/icons';
const b = block('privacy-policy-page');

export const PrivacyPolicyPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Layout 
      mainContentBgColor='#f2f2f2'
      sxContent={{
        height: 'unset'
      }}
    >
      <div className={b()}>
        <Stack width='100%' direction='row' justifyContent='flex-start'>
          <Button
            className={b("back-btn")} 
            view='flat' 
            size='xl' 
            onClick={() => navigate(-1)}
          >
            <Icon data={ChevronLeft} size={22} />
            Назад
          </Button>
        </Stack>  

        <h1 className='fs28-primary-bold'>
          Политика конфиденциальности
        </h1>

        <p className='fs20-primary-bold'>
          1. Общие положения
        </p>
        <p className='fs15-secondary-thin'>
          1.1. Настоящая Политика конфиденциальности (далее — Политика) разработана для сайта btag.bag24.ru (далее — сайт) и определяет порядок обработки и защиты персональных данных пользователей сайта (далее — Пользователи) в соответствии с Федеральным законом от 27 июля 2006 г. № 152-ФЗ «О персональных данных» (далее — Закон), а также другими применимыми законами Российской Федерации.  
        </p>
        <p className='fs15-secondary-thin'>
          1.2. Использование сайта означает согласие Пользователя с настоящей Политикой и условиями обработки его персональных данных.  
        </p>
        <p className='fs15-secondary-thin'>
          1.3. В случае несогласия с условиями Политики Пользователь должен прекратить использование сайта.
        </p>
        <p className='fs15-secondary-thin'>
          1.4. Настоящая Политика применяется только к сайту. Сайт не контролирует и не несёт ответственность за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на сайте.
        </p>
        <p className='fs15-secondary-thin'>
          1.5. Администрация сайта не проверяет достоверность персональных данных, предоставляемых Пользователями сайта.
        </p>

        <p className='fs20-primary-bold'>
          2. Персональные данные
        </p>
        <p className='fs15-secondary-thin'>
          2.1. Персональные данные, которые собирает и обрабатывает сайт, включают:<br /><br />

          • Имя и фамилия;<br /><br />

          • Контактный телефон;<br /><br />

          • Адрес электронной почты;<br /><br />

          • Адрес доставки;<br /><br />

          • Информация о платежах и заказах;<br /><br />

          • Иные данные, предоставленные Пользователем добровольно.  
        </p>
        <p className='fs15-secondary-thin'>
          2.2. Сайт собирает только те персональные данные, которые необходимы для предоставления услуг и выполнения обязательств перед Пользователем в соответствии с частью 2 статьи 6 Закона.
        </p>

        <p className='fs20-primary-bold'>
          3. Цели обработки персональных данных
        </p>
        <p className='fs15-secondary-thin'>
          3.1. Персональные данные Пользователя могут использоваться в следующих целях:<br /><br />

          • Идентификация Пользователя для оформления и выполнения заказов; Обратная связь с Пользователем, включая отправку уведомлений, запросов и информации, связанной с использованием сайта;<br /><br />

          • Улучшение качества обслуживания и доработка функционала сайта;<br /><br />

          • Проведение статистических и иных исследований на основе обезличенных данных.<br /><br />
        </p>

        <p className='fs20-primary-bold'>
          4. Условия обработки персональных данных
        </p>
        <p className='fs15-secondary-thin'>
          4.1. Обработка персональных данных Пользователя осуществляется без ограничения срока любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств, в соответствии с частью 1 статьи 5 Закона.
        </p>
        <p className='fs15-secondary-thin'>
          4.2. Персональные данные Пользователя могут быть переданы третьим лицам в следующих случаях:<br /><br />

          • Пользователь выразил согласие на такие действия (пункт 4 часть 1 статьи 6 Закона);<br /><br />

          • Передача необходима для использования Пользователем определённого сервиса или для выполнения конкретного соглашения с Пользователем (пункт 5 часть 1 статьи 6 Закона);<br /><br />

          • Передача предусмотрена российским или иным применимым законодательством в рамках установленной законодательством процедуры (пункт 2 часть 1 статьи 6 Закона).<br /><br />
        </p>

        <p className='fs20-primary-bold'>
          5. Защита персональных данных
        </p>
        <p className='fs15-secondary-thin'>
          5.1. Сайт принимает необходимые организационные и технические меры для защиты персональных данных Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц в соответствии со статьёй 19 Закона.
        </p>

        <p className='fs20-primary-bold'>
          6. Права пользователя
        </p>
        <p className='fs15-secondary-thin'>
          6.1. Пользователь имеет право:<br /><br />

          • Требовать уточнения своих персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки (часть 1 статья 14 Закона);<br /><br />

          • Отозвать согласие на обработку персональных данных (часть 2 статья 9 Закона);<br /><br />

          • Получать информацию, касающуюся обработки его персональных данных (часть 7 статья 14 Закона).<br /><br />
        </p>

        <p className='fs20-primary-bold'>
          7. Заключительные положения
        </p>
        <p className='fs15-secondary-thin'>
          7.1. Администрация сайта имеет право вносить изменения в настоящую Политику без согласия Пользователя.
        </p> 
        <p className='fs15-secondary-thin'>
          7.2. Новая Политика вступает в силу с момента её размещения на сайте, если иное не предусмотрено новой редакцией Политики.
        </p>
        <p className='fs15-secondary-thin'>
          7.3. Все предложения или вопросы по настоящей Политике следует сообщать через форму обратной связи, размещённую на сайте.
        </p>
        
        <p className='fs20-primary-bold'>
          8. Применимые законы
        </p>
        <p className='fs15-secondary-thin'>
          8.1. В дополнение к Федеральному закону от 27 июля 2006 г. № 152-ФЗ «О персональных данных», сайт также соблюдает следующие законы и нормативные акты Российской Федерации:<br /><br />

          • Федеральный закон от 27 июля 2006 г. № 149-ФЗ «Об информации, информационных технологиях и о защите информации» (особенно статьи 10 и 16);<br /><br />

          • Федеральный закон от 7 февраля 1992 г. № 2300–1 «О защите прав потребителей» (особенно статья 16);<br /><br />

          • Гражданский кодекс Российской Федерации (часть I и II, особенно главы 9, 10 и 39).<br /><br />
        </p> 
        <p className='fs15-secondary-thin'>
          8.2. Все действия по обработке персональных данных осуществляются в строгом соответствии с вышеуказанными законами и нормативными актами.
        </p>
        <p className='fs15-secondary-thin'>
          8.3. В случае возникновения вопросов или претензий относительно обработки персональных данных Пользователи могут обращаться в контролирующие органы, такие как Роскомнадзор.
        </p>

      </div>
    </Layout>
  );
};