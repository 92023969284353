import {rootApi} from '..';
import { objectToParamString } from '../../../utils/params';
import { 
  LinkTagPostParams,
  TagsGetParams, 
  UpdateTagPostParams
} from './types/request';
import { 
  GetTagByIdResponse,
  GetTagsResponse, 
  LinkTagResponse 
} from './types/response';

const tagApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getTags: build.query<GetTagsResponse, TagsGetParams>({
      query: (params) => {
        const p = {...params, limit: 10};
        return ({
          url: `/tags/${
            typeof(p) == 'object' 
            ? objectToParamString(p)
            : p}`,
        })
      },
    }),
    getTagById: build.query<GetTagByIdResponse, string>({
      query: (tagId) => {
        return ({
          url: `/tags/${tagId}`,
        })
      },
    }),
    linkTag: build.mutation<LinkTagResponse, LinkTagPostParams>({
      query: (params) => {
        return ({
          url: `/tags/`,
          method: 'POST',
          data: params
        })
      },
    }),
    updateTag: build.mutation<LinkTagResponse, [string, UpdateTagPostParams]>({
      query: (params) => {
        const [id, body] = params;
        return ({
          url: `/tags/${id}`,
          method: 'PUT',
          data: body
        })
      },
    })
  }),
  overrideExisting: false,
});

export const {
  useLazyGetTagByIdQuery,
  useGetTagByIdQuery,
  useLazyGetTagsQuery,
  useLinkTagMutation,
  useUpdateTagMutation
} = tagApi;
