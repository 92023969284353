import {rootApi} from '..';
import {
  ConfirmLoginPostParams, 
  LoginPostParams,
  UpdateUserPostParams
} from './types/request';
import { 
  GetUserResponse,
  PostConfirmLoginResponse, 
  PutUpdateUserResponse
} from './types/response';

const authApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<void, LoginPostParams>({
      query: (data) => ({
        url: '/accounts/init',
        method: 'POST',
        data
      }),
    }),
    confirmLogin: build.mutation<PostConfirmLoginResponse, ConfirmLoginPostParams>({
      query: (data) => ({
        url: '/accounts/confirm',
        method: 'POST',
        data
      }),
    }),
    updateUser: build.mutation<PutUpdateUserResponse, UpdateUserPostParams>({
      query: (data) => ({
        url: '/accounts/',
        method: 'PUT',
        data
      }),
      invalidatesTags: ["User"]
    }),
    deleteUser: build.mutation<void, void>({
      query: () => ({
        url: '/accounts/',
        method: 'DELETE'
      }),
    }),
    getUser: build.query<GetUserResponse, void>({
      query: () => ({
        url: '/accounts/me',
      }),
      providesTags: ["User"]
    }),
  }),
  overrideExisting: false,
});

export const {
  useLoginMutation,
  useConfirmLoginMutation,
  useUpdateUserMutation,
  useLazyGetUserQuery,
  useDeleteUserMutation
} = authApi;
