import React from 'react';
import block from 'bem-cn-lite';
import { Box, Collapse, Stack, Tooltip } from '@mui/material';

import './LinkTag.scss';
import {Layout} from '../../components/layout';
import { Steper } from '../../components/steper';
import { Button, Icon, RadioButton, RadioButtonOption, Spin, Switch, Text, TextArea, TextInput, useToaster } from '@gravity-ui/uikit';
import {ChevronLeft, CircleInfoFill, CircleXmarkFill } from '@gravity-ui/icons';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { NavigationPath } from '../../utils/constant/navigation';
import { InputLabel } from '../../components/input-label';
import { ContactCard } from '../../components/contact-card';
import { PrimaryButton } from '../../components/button';

import { ReactComponent as PhoneIcon} from '../../assets/icons/phone.svg';
import { ReactComponent as MailIcon} from '../../assets/icons/mail.svg';
import { ReactComponent as TelegramIcon} from '../../assets/icons/telegram.svg';
// import { ReactComponent as ViberIcon} from '../../assets/icons/viber.svg';
// import { ReactComponent as VKIcon} from '../../assets/icons/vk.svg';
import { ReactComponent as WhatsAppIcon} from '../../assets/icons/whatsapp.svg';
import { useLazyGetTagByIdQuery, useLinkTagMutation, useUpdateTagMutation } from '../../store/api/tag';
import { clearFormattedPhoneNumber, formatPhoneNumber, isPhoneNumberValid } from '../../utils/phonenumber';
import { isDigitOrEmpty } from '../../utils/validation';
import { useLazyGetUserQuery } from '../../store/api/auth';
import moment from 'moment';


const b = block('link-tag-page');

interface TagInfo {
  name: string,
  email: string,
  phoneNumber: string,
  whatsapp: string,
  clearWhatsapp: string,
  description: string,
  clearPhoneNumber: string,
  reward: string,
  ownerName: string,
  telegram: {
    clearPhoneNumber: string,
    phoneNumber: string,
    username: string,
  }
}

interface OpenedContacts {
  email: boolean,
  phoneNumber: boolean,
  whatsapp: boolean,
  telegram: boolean
}

export const LinkTagPage: React.FC = () => {
  const { state } = useLocation();

  const {add} = useToaster();
  const navigate = useNavigate();
  const { tagId } = useParams<{tagId: string}>()

  const [linkTag, linkTagRequestInfo] = useLinkTagMutation();
  const [updateTag, updateTagRequestInfo] = useUpdateTagMutation();
  const [getTagById, {
    data: getTagByIdResponse,
    ...getTagByIdRequestInfo
  }] = useLazyGetTagByIdQuery();
  const [getUser, {
    data: getUserResponse,
    ...getUserRequestInfo
  }] = useLazyGetUserQuery();

  const [step, setStep] = React.useState(1);
  const nextStep = () => setStep(step + 1); 
  const prevStep = () => setStep(step - 1);

  const [open, setOpen] = React.useState(false);
  const handleTooltipClose = () => setOpen(false);
  const handleTooltipOpen = () => setOpen(true);

  const [showReward, setShowReward] = React.useState(false);
  const [tagInfo, setTagInfo] = React.useState<TagInfo>({
    name: "",
    description: "",
    email: "",
    phoneNumber: "",
    clearPhoneNumber: "",
    whatsapp: "",
    clearWhatsapp: "",
    reward: "",
    ownerName: "",
    telegram: {
      clearPhoneNumber: "",
      phoneNumber: "",
      username: "",
    }
  });
  const [openedContacts, setOpenedContacts] = React.useState<OpenedContacts>({
    email: false,
    phoneNumber: false,
    telegram: false,
    whatsapp: false
  })

  const editSections: RadioButtonOption[] = [
    {value: 'contacts', content: 'Личные данные'},
    {value: 'tag', content: 'Метка BTAG'},
  ];
  const [currentEditSection, setCurrentEditSection] = React.useState<string>(editSections[0].value);

  const handleOnTagInfoChange = (value: {[key: string]: any}) => {
    setTagInfo({...tagInfo, ...value});
  }

  const handleOnShowRewardChange = () => {
    setShowReward(!showReward);
  }

  const handleOnUpdateTagButtonClick = () => {
    if (!tagId) return;

    const { clearPhoneNumber, clearWhatsapp, telegram, ...body } = tagInfo;
    if (clearPhoneNumber && !isPhoneNumberValid(clearPhoneNumber)) {
      add({
        name: 'error',
        autoHiding: 3000,
        isClosable: true,
        title: `Неправильный формат номера телефона`,
        renderIcon: () => <Icon className="success-red" data={CircleXmarkFill} size={18} />,
      });
      return;
    }

    if (clearWhatsapp && !isPhoneNumberValid(clearWhatsapp)) {
      add({
        name: 'error',
        autoHiding: 3000,
        isClosable: true,
        title: `Неправильный формат номера телефона Whatsapp`,
        renderIcon: () => <Icon className="success-red" data={CircleXmarkFill} size={18} />,
      });
      return;
    }

    if (telegram.clearPhoneNumber && !isPhoneNumberValid(telegram.clearPhoneNumber)) {
      add({
        name: 'error',
        autoHiding: 3000,
        isClosable: true,
        title: `Неправильный формат номера телефона Telegram`,
        renderIcon: () => <Icon className="success-red" data={CircleXmarkFill} size={18} />,
      });
      return;
    }

    let updateTagBody: any = {
      ...body,
      phoneNumber: clearPhoneNumber ? "+" + clearPhoneNumber : null,
      whatsapp: clearWhatsapp ? "+" + clearWhatsapp : null,
      telegram: {
        phoneNumber: telegram.clearPhoneNumber 
          ? "+" + telegram.clearPhoneNumber
          : null,
        username: telegram.username
      }
    }

    updateTagBody = Object.keys(updateTagBody).reduce((prev: any, curr: string) => {
      if (curr === "telegram") {
        if (updateTagBody[curr].username || updateTagBody[curr].phoneNumber) {
          return ({...prev, [curr]: updateTagBody[curr]});
        } else {
          return prev;
        }
      } else {
        if (updateTagBody[curr]) {
          return ({...prev, [curr]: updateTagBody[curr]});
        } else {
          return prev;
        }
      }
    }, {});

    if (!showReward) {
      updateTagBody.reward = null;
    }

    updateTag([tagId, updateTagBody]);
  };


  React.useEffect(() => {
    if (step === 0) {
      navigate(NavigationPath.MainPage);
    }
  }, [step]) 

  React.useEffect(() => {
    if (tagId) {
      if (state && state?.purpose === "update") {
        getTagById(tagId);
      } else {
        getUser();
        linkTag({ tagId });
      }
    }
  }, [tagId, state]) 

  React.useEffect(() => {
    if (getUserRequestInfo.isSuccess) {
      setTagInfo({
        phoneNumber: formatPhoneNumber(clearFormattedPhoneNumber(getUserResponse?.result.phoneNumber || "")),
        clearPhoneNumber: clearFormattedPhoneNumber(getUserResponse?.result.phoneNumber || ""),
        description: "",
        email: getUserResponse?.result.email || "",
        name: "",
        whatsapp: formatPhoneNumber(clearFormattedPhoneNumber(getUserResponse?.result.whatsapp || "")),
        clearWhatsapp: clearFormattedPhoneNumber(getUserResponse?.result.whatsapp || ""),
        reward: "",
        ownerName: getUserResponse?.result.name || "",
        telegram: {
          phoneNumber: formatPhoneNumber(clearFormattedPhoneNumber(getUserResponse?.result.telegram?.phoneNumber || "")),
          clearPhoneNumber: clearFormattedPhoneNumber(getUserResponse?.result.telegram?.phoneNumber || ""),
          username: getUserResponse?.result.telegram?.username || ""
        }
      });
      setOpenedContacts({
        phoneNumber: Boolean(getUserResponse?.result.phoneNumber),
        telegram: Boolean(getUserResponse?.result.telegram),
        whatsapp: Boolean(getUserResponse?.result.whatsapp),
        email: Boolean(getUserResponse?.result.email)
      });
    }
  }, [getUserRequestInfo.isSuccess]) 

  React.useEffect(() => {
    if (getTagByIdRequestInfo.isSuccess && getTagByIdResponse) {
      setTagInfo({
        phoneNumber: formatPhoneNumber(clearFormattedPhoneNumber(getTagByIdResponse?.result.phoneNumber || "")),
        clearPhoneNumber: clearFormattedPhoneNumber(getTagByIdResponse?.result.phoneNumber || ""),
        description: getTagByIdResponse?.result.description || "",
        email: getTagByIdResponse?.result.email || "",
        name: getTagByIdResponse?.result.name || "",
        whatsapp: formatPhoneNumber(clearFormattedPhoneNumber(getTagByIdResponse?.result.whatsapp || "")),
        clearWhatsapp: clearFormattedPhoneNumber(getTagByIdResponse?.result.whatsapp || ""),
        reward: getTagByIdResponse?.result.reward?.toString() || "",
        ownerName: getTagByIdResponse?.result.ownerName || "",
        telegram: {
          phoneNumber: formatPhoneNumber(clearFormattedPhoneNumber(getTagByIdResponse?.result.telegram?.phoneNumber || "")),
          clearPhoneNumber: clearFormattedPhoneNumber(getTagByIdResponse?.result.telegram?.phoneNumber || ""),
          username: getTagByIdResponse?.result.telegram?.username || ""
        }
      });
      setOpenedContacts({
        phoneNumber: Boolean(getTagByIdResponse?.result.phoneNumber),
        telegram: Boolean(getTagByIdResponse?.result.telegram),
        whatsapp: Boolean(getTagByIdResponse?.result.whatsapp),
        email: Boolean(getTagByIdResponse?.result.email)
      })

      setShowReward(Boolean(getTagByIdResponse?.result.reward));
    }
  }, [getTagByIdRequestInfo.isSuccess, getTagByIdResponse])

  React.useEffect(() => {
    if (updateTagRequestInfo.isSuccess) {
      navigate(`${NavigationPath.SuccessLinkingPage}/${tagId}`);
    }
  }, [updateTagRequestInfo.isSuccess])

  React.useEffect(() => {
    if (getTagByIdRequestInfo.isError) {
      add({
        name: 'error',
        autoHiding: 3000,
        isClosable: true,
        title: `Ошибка в запросе`,
        renderIcon: () => <Icon className="success-red" data={CircleXmarkFill} size={18} />,
        // @ts-ignore
        content: getTagByIdRequestInfo.error.error.message,
      });
      navigate(NavigationPath.MainPage);
    }
  }, [getTagByIdRequestInfo.isError, getTagByIdRequestInfo.error])

  React.useEffect(() => {
    if (updateTagRequestInfo.isError) {
      add({
        name: 'error',
        autoHiding: 3000,
        isClosable: true,
        title: `Ошибка в запросе`,
        renderIcon: () => <Icon className="success-red" data={CircleXmarkFill} size={18} />,
        // @ts-ignore
        content: updateTagRequestInfo.error.error.message,
      });
    }
  }, [updateTagRequestInfo.isError, updateTagRequestInfo.error])

  React.useEffect(() => {
    if (linkTagRequestInfo.isError) {
      add({
        name: 'error',
        autoHiding: 3000,
        isClosable: true,
        title: `Ошибка в запросе`,
        renderIcon: () => <Icon className="success-red" data={CircleXmarkFill} size={18} />,
        // @ts-ignore
        content: linkTagRequestInfo.error.error.message,
      });
      navigate(NavigationPath.MainPage);
    }
  }, [linkTagRequestInfo.isError, linkTagRequestInfo.error])

  return (
    <Layout 
      layoutBgColor={updateTagRequestInfo.isLoading ? '#ffffff' : "#f2f2f2"}
      header={
        updateTagRequestInfo.isLoading || getTagByIdRequestInfo.isLoading
          ? undefined
          : <Stack 
              position='relative'
              width='100%'
              alignItems='center'
              justifyContent='center'
              sx={{
                padding: "24px 8px 16px 8px"
              }}
            >
              <Button 
                view='flat'
                onClick={prevStep} 
                className={b('back-button')}
              >
                <Icon data={ChevronLeft} size={22} />
              </Button>
              {
                state?.purpose === "update" && getTagByIdRequestInfo.isSuccess
                ? <>
                    <span className='fs17-primary-bold'>{getTagByIdResponse?.result.name}</span>
                    <span className='fs13-grey-thin'>до {moment(getTagByIdResponse?.result.expiresAt).format("DD.MM.YYYY")}</span>
                  </>
                : <Steper step={step} count={2} />
              }
              
            </Stack>
      }
    >
      <div className={b()}>
        {linkTagRequestInfo.isLoading
          ? <Stack width='100%' height='100%' justifyContent='center' alignItems='center'>
              <Spin size='xl' className='spin' />
            </Stack>
          : null
        }
        {updateTagRequestInfo.isLoading
          ? <Stack width='100%' height='100%' justifyContent='center' alignItems='center'>
              <Spin size='xl' className='spin' style={{ marginBottom: 12 }}/>
              <p className='fs28-primary-bold'>
                Ваш BTAG готовится...
              </p>
              <span className='fs15-secondary-thin'>
                Связываем данные метки с QR-кодом
              </span>
            </Stack>
          : null
        }

        {state?.purpose === "update"
          ? <RadioButton 
              name="edit-sections" 
              value={currentEditSection}
              onChange={(event: any) => setCurrentEditSection(event.target.value)}
              options={editSections}
              className={b("edit-sections")}          
            />
          : null
        }

        {(linkTagRequestInfo.isSuccess || (state?.purpose === "update" && getTagByIdRequestInfo.isSuccess)) && !updateTagRequestInfo.isLoading
          ? <>
              {(state?.purpose !== "update" && step === 1) || (state?.purpose === "update" && currentEditSection === "contacts")
                ? <Box pb={1} overflow='auto'>

                    {state?.purpose === "update"
                      ? <Text variant='header-1'>
                          Личные данные
                        </Text>
                      : <h1 className='fs28-primary-bold'>
                          Личные данные
                        </h1>
                    }

                    {state?.purpose === "update"
                      ? null
                      : <p className='fs15-secondary-thin'>
                          Заполните данные ниже, чтобы добавить 
                          контакты и соц. сети в вашу визитку
                        </p>
                    }

                    {state?.purpose === "update"
                      ? null
                      : <Text variant='header-1'>
                          Обо мне
                        </Text>
                    }
                    
                    <InputLabel labelText='Как к вам обращаться'>
                      <TextInput 
                        size='xl'
                        hasClear
                        value={tagInfo.ownerName}
                        onChange={(e: any) => handleOnTagInfoChange({ ownerName: e.target.value })}
                      />
                    </InputLabel>

                    <Text variant='header-1' className='contacts'>
                      Что покажем нашедшему?
                    </Text>

                    <Box
                      px={0.2}
                      pt={1}
                      pb={7}
                    >
                      <Stack direction='column' spacing={2}>
                        <ContactCard Icon={PhoneIcon} title='Номер телефона' open={openedContacts.phoneNumber}>
                          <TextInput
                            size='xl'
                            value={tagInfo.phoneNumber}
                            onChange={(e: any) => {
                              const clearedPhoneNumber = clearFormattedPhoneNumber(e.target.value);
                              if (isDigitOrEmpty(clearedPhoneNumber)) {
                                handleOnTagInfoChange({ 
                                  phoneNumber: formatPhoneNumber(clearedPhoneNumber),
                                  clearPhoneNumber: clearedPhoneNumber,
                                })}
                              }
                            }
                          />
                        </ContactCard>
                        <ContactCard Icon={TelegramIcon} title='Telegram' open={openedContacts.telegram}>
                          <TextInput
                            size='xl'
                            value={tagInfo.telegram.phoneNumber}
                            placeholder='+7 (912) 312-31-23'
                            onChange={(e: any) => {
                              const clearedPhoneNumber = clearFormattedPhoneNumber(e.target.value);
                              if (isDigitOrEmpty(clearedPhoneNumber)) {
                                handleOnTagInfoChange({ 
                                  telegram: {
                                    phoneNumber: formatPhoneNumber(clearedPhoneNumber),
                                    clearPhoneNumber: clearedPhoneNumber,
                                    userInfo: tagInfo.telegram.username
                                  }
                                })}
                              }
                              
                            }
                          />
                          <TextInput
                            size='xl'
                            value={tagInfo.telegram.username}
                            placeholder='maxlick'
                            endContent={<Tooltip 
                              title="username должен быть без @"
                              onClose={handleTooltipClose}
                              open={open}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              slotProps={{
                                popper: {
                                  disablePortal: true,
                                },
                              }}
                            >
                              <Button view='flat' onClick={handleTooltipOpen}>
                                <Icon data={CircleInfoFill} size={18} />
                              </Button>
                            </Tooltip>}
                            onChange={(e: any) => handleOnTagInfoChange({ 
                              telegram: {
                                username: e.target.value,
                                phoneNumber: formatPhoneNumber(tagInfo.telegram.clearPhoneNumber),
                                clearPhoneNumber: tagInfo.telegram.clearPhoneNumber,
                              } 
                            })}
                          />
                        </ContactCard>
                        <ContactCard Icon={MailIcon} title='Почта' open={openedContacts.email}>
                          <TextInput
                            size='xl'
                            value={tagInfo.email}
                            onChange={(e: any) => handleOnTagInfoChange({ email: e.target.value })}
                          />
                        </ContactCard>
                        <ContactCard Icon={WhatsAppIcon} title='WhatsApp' open={openedContacts.whatsapp}>
                          <TextInput
                            size='xl'
                            value={tagInfo.whatsapp}
                            placeholder='+7 (912) 312-31-23'
                            onChange={(e: any) => {
                              const clearedPhoneNumber = clearFormattedPhoneNumber(e.target.value);
                              if (isDigitOrEmpty(clearedPhoneNumber)) {
                                handleOnTagInfoChange({ 
                                  whatsapp: formatPhoneNumber(clearedPhoneNumber),
                                  clearWhatsapp: clearedPhoneNumber,
                                })}
                              }
                            }
                          />
                        </ContactCard>
                      </Stack>
                    </Box>

                    <PrimaryButton
                      size='xl'
                      width='max'
                      onClick={ state?.purpose === "update" ? handleOnUpdateTagButtonClick : nextStep}
                    >
                      {state && state?.purpose === "update" ? "Сохранить" : "Далее"}
                    </PrimaryButton>
                  </Box>
                : (state?.purpose !== "update" && step === 2) || (state?.purpose === "update" && currentEditSection === "tag") 
                  ? <Box pb={6} overflow='auto'>
                      
                      {state?.purpose === "update"
                        ? <Text variant='header-1'>
                            Данные о метке
                          </Text>
                        : <h1 className='fs28-primary-bold'>
                            Данные о метке
                          </h1>
                      }

                      {state?.purpose === "update"
                        ? null
                        : <p className='fs15-secondary-thin'>
                            Укажите название метки и сообщение
                            для нашедшего. Еще вы можете добавить
                            вознаграждение за возврат вещи
                          </p>
                      }

                      <InputLabel labelText='Название метки'>
                        <TextInput 
                          size='xl'
                          hasClear
                          value={tagInfo.name}
                          onChange={(e: any) => handleOnTagInfoChange({ name: e.target.value })}
                        />
                      </InputLabel>

                      <InputLabel 
                        labelText='Сообщение нашедшему'
                        limit={150}
                        currentCount={tagInfo.description.length}
                      >
                        <TextArea
                          size='xl'
                          minRows={5}
                          maxRows={7}
                          value={tagInfo.description}
                          controlProps={{
                            maxLength: 150
                          }}
                          onChange={(e: any) => handleOnTagInfoChange({ description: e.target.value })}
                        />
                      </InputLabel>
                      
                      <Stack direction='row' spacing={2}>
                        <p className='fs15-secondary-thin'>Вознаграждение за находку</p>
                        <Switch checked={showReward} onChange={handleOnShowRewardChange} />
                      </Stack>
                      <Collapse in={showReward} timeout='auto' unmountOnExit>
                        <InputLabel labelText='Сумма вознаграждения'>
                          <TextInput 
                            size='xl'
                            hasClear
                            value={tagInfo.reward}
                            onChange={(e: any) => handleOnTagInfoChange({ reward: isDigitOrEmpty(e.target.value) ? e.target.value : tagInfo.reward })}
                          />
                        </InputLabel>
                      </Collapse>
                      

                      <PrimaryButton
                        size='xl'
                        width='max'
                        onClick={handleOnUpdateTagButtonClick}
                      >
                        {state && state?.purpose === "update" ? "Сохранить" : "Создать метку"}
                      </PrimaryButton>

                    </Box>
                  : null
              }
          </>
          : null
        }
        

      </div>
    </Layout>
  );
};