import React from 'react';
import block from 'bem-cn-lite';
import { Icon, Label, Text } from '@gravity-ui/uikit';
import { Box, Stack } from '@mui/material';

import './PreviewBanner.scss';
import { CircleDollar } from '@gravity-ui/icons';

const b = block('preview-banner');

interface PreviewBannerProps {
  name: string,
  description: string,
  cost?: number | null,
}

export const PreviewBanner: React.FC<PreviewBannerProps> = ({
  name,
  description,
  cost
}) => (
  <Stack 
    className={b()}
    direction='row'
    alignItems='flex-start'
    justifyContent='flex-start'
  >
    <Box flexGrow={1} pl={1}>
      <Text variant='body-3'>
        {name}
      </Text>
      <Text color='secondary'>
        {description}
      </Text>
      {cost
        ? <Label 
            theme='success'
            icon={
              <Icon data={CircleDollar} size={12} />
            }
          >
            Вознаграждение - {cost}
          </Label>
        : null
      }
    </Box>
  </Stack>
);
