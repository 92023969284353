import React from 'react';
import block from 'bem-cn-lite';
import './SuccessLinking.scss';
import {Layout} from '../../components/layout';
import { PreviewSheet } from '../../components/preview-sheet';

import successIcon from '../../assets/success.png';
import { Box, Stack } from '@mui/material';
import { Button, Icon } from '@gravity-ui/uikit';
import { Eye } from '@gravity-ui/icons';
import { PrimaryButton } from '../../components/button';
import { useNavigate, useParams } from 'react-router-dom';
import { NavigationPath } from '../../utils/constant/navigation';

const b = block('success-linking-page');

export const SuccessLinkingPage: React.FC = () => {
  const { tagId } = useParams<{tagId: string}>();
  const navigate = useNavigate();

  const [openPreviewMark, setOpenPreviewMark] = React.useState(false);
  const handleOnPreviewTagClick = () => setOpenPreviewMark(true);
  const closePreviewMark = () => setOpenPreviewMark(false);

  const handleOnMainBtnClick = () => navigate(NavigationPath.MainPage);

  return (
    <Layout layoutBgColor='#ffffff'>
      <div className={b()}>
        <Box width='100%' flexGrow={1} className={b()}>
          <Box className={b("img-wrapper")}>
            <img src={successIcon} />
          </Box>
          <p className='fs28-primary-bold'>
            Ваш BTAG готов!
          </p>
          <span className='fs15-secondary-thin'>
            Скорее прикрепите метку
          </span>
        </Box>
        <Stack width='100%' spacing={1}>
          <Button size='xl' width='max' onClick={handleOnPreviewTagClick}>
            Показать превью
            <Icon data={Eye} size={18} />
          </Button>
          <PrimaryButton size='xl' width='max' onClick={handleOnMainBtnClick}>
            На главную
          </PrimaryButton>
        </Stack>
        
      </div>
      
      <PreviewSheet 
        tagId={tagId!}
        open={openPreviewMark}
        onClose={closePreviewMark}
      />
    </Layout>
  );
};