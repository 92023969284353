import React from 'react';
import block from 'bem-cn-lite';
import './InactiveTag.scss';
import {Layout} from '../../components/layout';

import errorIcon from '../../assets/error.png';
import { Box, Stack } from '@mui/material';
import { PrimaryButton } from '../../components/button';
import { useNavigate } from 'react-router-dom';
import { NavigationPath } from '../../utils/constant/navigation';

const b = block('inactive-tag-page');

export const InactiveTagPage: React.FC = () => {
  const navigate = useNavigate();

  const handleOnMainBtnClick = () => {
    navigate(NavigationPath.MainPage);
  }

  return (
    <Layout mainContentBgColor='#f2f2f2'>
      <div className={b()}>
        <Box width='100%' flexGrow={1} className={b()}>
          <Box className={b("img-wrapper")}>
            <img src={errorIcon} />
          </Box>
          <p className='fs28-primary-bold'>
            К сожалению, данный тег недействителен
          </p>
        </Box>
        <Stack width='100%' spacing={1}>
          <PrimaryButton size='xl' width='max' onClick={handleOnMainBtnClick}>
            На главную
          </PrimaryButton>
        </Stack>
      </div>
    </Layout>
  );
};