import React from 'react';
import block from 'bem-cn-lite';
import { useLocation, useNavigate } from 'react-router-dom';

// import {useNavigate} from 'react-router-dom';
// import {NavigationPath} from '../../utils/constant/navigation';
import { Stack } from '@mui/material';
import { Box, Button, Icon, PinInput } from '@gravity-ui/uikit';
import { PencilToLine } from '@gravity-ui/icons'

import './SendCode.scss';

import { Layout } from '../../components/layout';
import { PrimaryButton } from '../../components/button';
import { InputLabel } from '../../components/input-label';
import { NavigationPath } from '../../utils/constant/navigation';
import { useConfirmLoginMutation, useLazyGetUserQuery } from '../../store/api/auth';
import { formatPhoneNumber } from '../../utils/phonenumber';
import { ObjectStorage, ObjectStorageKey } from '../../hooks/storage';

const b = block('send-code-page');

const TIMER_SECONDS = 60

export const SendCodePage: React.FC = () => {
  const { state: {
    phoneOrEmail,
    isPhone,
    nextPage
  }} = useLocation();

  const navigate = useNavigate();

  const [sendCode, {
    data: sendCodeResponse,
    ...sendCodeRequestInfo
  }] = useConfirmLoginMutation();

  const [getUser] = useLazyGetUserQuery();

  const [error, setError] = React.useState('');
  const [allowSendAgain, setAllowSendAgain] = React.useState(false);
  const [idTimer, setIdTimer] = React.useState<NodeJS.Timeout>();
  const [timer, setTimer] = React.useState(TIMER_SECONDS);
  const [code, setCode] = React.useState<string[]>([]);
  const handleOnChangeCode = (newCode: string[] ) => setCode(newCode);

  const [isSendCodeButtonClicked, setIsSendCodeButtonClicked] = React.useState(false);

  const handleOnCodeComplete = (code: string[]) => {
    sendCode({
      otp: code.join(""),
      login: isPhone ? "+" + phoneOrEmail : phoneOrEmail
    })
  };

  const initTimer = () => {
    setIdTimer(
      setInterval(() => {
        setTimer(currentTimer => currentTimer - 1)
      }, 1000)
    );
  }

  const handleOnClickSendAgainButton = () => {
    setAllowSendAgain(false);
    initTimer();
  }

  const handleOnClickEditBtn = () => {
    navigate(NavigationPath.LoginPage)
  }

  React.useEffect(() => {
    if (sendCodeRequestInfo.isSuccess) {
      localStorage.setItem('accessToken', sendCodeResponse?.result.token!);
      localStorage.setItem('refreshToken', sendCodeResponse?.result.refreshToken!);
      getUser().then(r => {
        ObjectStorage.setValue(ObjectStorageKey.user, r?.data?.result || {})
      });
      setIsSendCodeButtonClicked(false);
      if (nextPage) {
        navigate(nextPage);
      } else {
        navigate(NavigationPath.MainPage);
      }
    }
  }, [sendCodeRequestInfo.isSuccess, nextPage])

  React.useEffect(() => {
    if (sendCodeRequestInfo.isError) {
      setIsSendCodeButtonClicked(false);
      // @ts-ignore
      setError(sendCodeRequestInfo.error.error.message)
    }
  }, [sendCodeRequestInfo.isError])

  React.useEffect(() => {
    initTimer()
  }, [])

  React.useEffect(() => {
    if (idTimer && timer === 0) { 
      clearInterval(idTimer);
      setAllowSendAgain(true);
      setIdTimer(undefined);
      setTimer(TIMER_SECONDS);
    }
  }, [timer, idTimer])

  return (
    <Layout>
      <Stack className={b()}>

        <h1 className='fs24-primary-bold'>
          {isPhone 
            ? "Введите код из СМС:"
            : "Введите код из письма:"
          }
          
        </h1>
        <p className='fs15-secondary-thin'>
          {isPhone 
            ? "Мы отправили СМС с кодом на номер:"
            : "Мы отправили код на почту"
          }
        </p>
        <p className={b('phoneOrEmail')}>
          {isPhone ? formatPhoneNumber(phoneOrEmail) : phoneOrEmail}
          <Button 
            size='s' 
            view='flat' 
            onClick={handleOnClickEditBtn}
            className={b('edit')}
          >
            <Icon data={PencilToLine} size={14} />
          </Button>
        </p>
        <Box className={b('form')}>
          <InputLabel labelText={isPhone ? 'Введите код из СМС' : 'Введите код из письма'}>
            <PinInput
              otp
              size='xl'
              type="numeric" 
              autoFocus
              responsive
              length={6}
              value={code}
              errorMessage={error}
              validationState={sendCodeRequestInfo.isError ? "invalid" : undefined}
              onUpdateComplete={handleOnCodeComplete}
              onUpdate={handleOnChangeCode}
            />
          </InputLabel>
        </Box>
        <Stack
          direction='row'
          alignItems='center' 
          justifyContent='center'
        >
          <PrimaryButton 
            size='xl'
            width='max'
            loading={isSendCodeButtonClicked}
            disabled={!allowSendAgain}
            onClick={handleOnClickSendAgainButton}
          >
            {allowSendAgain
              ? "Отправить код повторно"
              : `Отправить код повторно через: 00:${timer.toString().padStart(2, "0")}`
            }
          </PrimaryButton>
        </Stack>

      </Stack>
    </Layout>
  );
};
