import {AuthPage} from '../pages/auth';
import {Navigate, Route, Routes, Outlet} from 'react-router-dom';
import {MainPage} from '../pages/main';
import {NavigationPath} from '../utils/constant/navigation';
import { SendCodePage } from '../pages/send-code';
import { ProfilePage } from '../pages/profile';
import { LinkTagPage } from '../pages/link-tag';
import { SuccessLinkingPage } from '../pages/success-linking';
import { TagPage } from '../pages/tag';
import { CameraPage } from '../pages/camera';
import { PrivacyPolicyPage } from '../pages/privacy-policy';
import { ProfileContactPage } from '../pages/profile-contact';
import { SuccessDeletePage } from '../pages/success-delete';
import { InactiveTagPage } from '../pages/inactive-tag';

const PrivateRoute = () => {
  const auth = localStorage.getItem('accessToken');
  return auth ? <Outlet /> : <Navigate to={NavigationPath.LoginPage} state={{ nextPage: window.location.pathname }} />;
};

const UnAuthRoute = () => {
  const auth = localStorage.getItem('accessToken');
  return !auth ? <Outlet /> : <Navigate to={NavigationPath.MainPage} />;
};

export const Routing = () => {
  return (
    <Routes>
      <Route path={NavigationPath.LoginPage} element={<UnAuthRoute />}>
        <Route path={NavigationPath.LoginPage} element={<AuthPage />} />
      </Route>
      <Route path={NavigationPath.SendCodePage} element={<UnAuthRoute />}>
        <Route path={NavigationPath.SendCodePage} element={<SendCodePage />} />
      </Route>

      <Route path={NavigationPath.MainPage} element={<PrivateRoute />}>
        <Route path={NavigationPath.MainPage} element={<MainPage />} />
      </Route>
      
      <Route path={`${NavigationPath.TagPage}/:tagId`} element={<TagPage />} />

      <Route path={`${NavigationPath.PrivacyPolicyPage}`} element={<PrivacyPolicyPage />} />

      <Route path={`${NavigationPath.InacitveTagPage}`} element={<InactiveTagPage />} />

      <Route path={NavigationPath.CameraPage} element={<PrivateRoute />}>
        <Route path={NavigationPath.CameraPage} element={<CameraPage />} />
      </Route>

      <Route path={NavigationPath.ProfilePage} element={<PrivateRoute />}>
        <Route path={NavigationPath.ProfilePage} element={<ProfilePage />} />
      </Route>

      <Route path={NavigationPath.ProfileContactPage} element={<PrivateRoute />}>
        <Route path={NavigationPath.ProfileContactPage} element={<ProfileContactPage />} />
      </Route>

      <Route path={`${NavigationPath.LinkTagPage}/:tagId`} element={<PrivateRoute />}>
        <Route path={`${NavigationPath.LinkTagPage}/:tagId`} element={<LinkTagPage />} />
      </Route>

      <Route path={`${NavigationPath.SuccessLinkingPage}/:tagId`} element={<PrivateRoute />}>
        <Route path={`${NavigationPath.SuccessLinkingPage}/:tagId`} element={<SuccessLinkingPage />} />
      </Route>

      <Route path={`${NavigationPath.SuccessDeletePage}`} element={<PrivateRoute />}>
        <Route path={`${NavigationPath.SuccessDeletePage}`} element={<SuccessDeletePage />} />
      </Route>

      <Route path={NavigationPath.Any} element={<PrivateRoute />}>
        <Route path={NavigationPath.Any} element={<Navigate to={NavigationPath.MainPage} />} />
      </Route>
      
    </Routes>
  );
};
