import React from 'react';
import block from 'bem-cn-lite';
import './SuccessDelete.scss';
import {Layout} from '../../components/layout';

import deleteIcon from '../../assets/delete.png';
import { Box, Stack } from '@mui/material';
import { PrimaryButton } from '../../components/button';
import { ObjectStorage } from '../../hooks/storage';
import { useNavigate } from 'react-router-dom';
import { NavigationPath } from '../../utils/constant/navigation';

const b = block('success-delete-page');

export const SuccessDeletePage: React.FC = () => {
  const navigate = useNavigate();

  const handleOnMainBtnClick = () => {
    ObjectStorage.clear();
    navigate(NavigationPath.LoginPage);
  }

  return (
    <Layout mainContentBgColor='#f2f2f2'>
      <div className={b()}>
        <Box width='100%' flexGrow={1} className={b()}>
          <Box className={b("img-wrapper")}>
            <img src={deleteIcon} />
          </Box>
          <p className='fs28-primary-bold'>
            Ваш профиль удален
          </p>
          <span className='fs15-secondary-thin'>
            Для того, чтобы создать аккаунт вновь, вы можете использовать номер телефона повторно
          </span>
        </Box>
        <Stack width='100%' spacing={1}>
          <PrimaryButton size='xl' width='max' onClick={handleOnMainBtnClick}>
            Войти или создать аккаунт
          </PrimaryButton>
        </Stack>
      </div>
    </Layout>
  );
};