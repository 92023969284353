import React from 'react';
import block from 'bem-cn-lite';
import { Button, Sheet } from '@gravity-ui/uikit';

import './LogoutUserSheet.scss';
import { Stack } from '@mui/material';

const b = block('logout-user-sheet');

interface LogoutUserSheetProps {
  open: boolean,
  onOk: () => void,
  onClose: () => void,
}

export const LogoutUserSheet: React.FC<LogoutUserSheetProps> = ({
  open,
  onOk,
  onClose
}) => {

  return (
    <Sheet 
      visible={open}
      onClose={onClose}
      className={b()}
    >
      <Stack p={2} width='100%' spacing={1}>
        <p className='fs20-primary-bold'>
          Вы действительно хотите выйти из аккаунта?
        </p>
      </Stack>
      <Stack p={2} pb={4} width='100%' spacing={2}>
        <Button
          size='xl'
          width='max'
          view='outlined-danger'
          onClick={onOk}
        >
          Выйти
        </Button>
        <Button
          size='xl'
          width='max'
          onClick={onClose}
        >
          Отменить
        </Button>
      </Stack>
    </Sheet>
  );
}
