import React from 'react';
import block from 'bem-cn-lite';
import { Switch, Text } from '@gravity-ui/uikit';
import Stack from '@mui/material/Stack';

import './ContactCard.scss';
import { Collapse } from '@mui/material';


const b = block('contact-card');

interface ContactCardProps {
  open?: boolean,
  title: string,
  children: React.ReactNode,
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
}

export const ContactCard: React.FC<ContactCardProps> = ({
  title,
  Icon,
  children,
  open = false,
}) => {

  const [show, setShow] = React.useState(open);
  const toggleShow = () => setShow(!show);

  React.useEffect(() => {
    if (open !== undefined) {
      setShow(open);
    }
  }, [open])

  return (
    <div className={b()}>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <Text className={b("title")}>
          <Icon />
          {title}
        </Text>
        <Switch checked={show} onChange={toggleShow}/>
      </Stack>
      <Collapse in={show} timeout='auto' unmountOnExit>
        <Stack mt={2} spacing={1}>
          {children}
        </Stack>
      </Collapse>
    </div>
  );
}
