import React from 'react';
import block from 'bem-cn-lite';
import { Button, Icon, Label } from '@gravity-ui/uikit';
import { CircleFill, Eye, PencilToLine } from '@gravity-ui/icons';
import Stack from '@mui/material/Stack';

import './TagCard.scss';
import { Box, Divider } from '@mui/material';
import { ITag } from '../../store/api/tag/types/models';
import moment from 'moment';


const b = block('tag-card');

interface TagCardProps {
  tag: ITag,
  onEdit: () => void,
  onPreview: () => void,
}

export const TagCard: React.FC<TagCardProps> = ({
  tag,
  onEdit,
  onPreview
}) => (
  <div className={b()}>
    <Box p={2}>
      <Label 
        theme={moment(tag.expiresAt) > moment() ? "success" : "danger"}
        icon={<Icon data={CircleFill} size={8} />}
      >
        {moment(tag.expiresAt) > moment()
          ? "Активна до " + moment(tag.expiresAt).format("DD.MM.YYYY")
          : "Просрочена "  + moment(tag.expiresAt).format("DD.MM.YYYY")
        }
      </Label>
      <p className='fs17-primary-bold'>
        {tag.name}
      </p>
    </Box>
    <Divider sx={{ borderColor: 'grey' }} />
    <Stack 
      direction='row' 
      width='100%' 
      spacing={1}
      pt={1.5}
      pb={2}
      px={2}
    >
      <Button 
        view='outlined'
        onClick={onPreview}
      >
        Превью
        <Icon data={Eye}/>
      </Button>
      <Button onClick={onEdit}>
        Редактировать
        <Icon data={PencilToLine}/>
      </Button>
    </Stack>
  </div>
);
