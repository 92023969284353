import React from 'react';
import block from 'bem-cn-lite';
import { Text } from '@gravity-ui/uikit';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/material';

import './Steper.scss';


const b = block('steper');

interface SteperProps {
  step: number,
  count: number,
}

export const Steper: React.FC<SteperProps> = ({
  step,
  count
}) => (
  <Stack
    className={b()} 
    direction='column' 
    justifyItems='center' 
    alignItems='center'
    spacing={0.5}
  >
    <Text>{step} из {count}</Text>
    <Box 
      position='relative'
      width={100} 
      height={3} 
      bgcolor='#e1e1e1'
      sx={{
        '&::before': {
          content: '""',
          position: 'absolute',
          width: `${(step / count) * 100}%`,
          height: '100%',
          backgroundColor: 'green'
        }
      }} 
    />
  </Stack>
);
