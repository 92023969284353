import React from "react";
import QrScanner from 'qr-scanner';

import './Camera.css'
import { useNavigate } from "react-router-dom";
import { NavigationPath } from "../../utils/constant/navigation";
import { Icon, useToaster } from "@gravity-ui/uikit";
import { CircleXmarkFill } from "@gravity-ui/icons";

export const CameraPage: React.FC = () => {
  const navigate = useNavigate();
  const {add} = useToaster();

  React.useEffect(() =>{
    const video = document.getElementById('qr-video');
    //@ts-ignore
    function setResult(result) {
        if (result.data) {
            const url = new URL(result.data || "#");
            navigate(url.pathname);
            scanner.stop();
        }
    }

    //@ts-ignore
    const scanner = new QrScanner(video, result => setResult(result), {
        highlightScanRegion: true,
        highlightCodeOutline: true,
    });
    
    QrScanner.hasCamera().then(hasCamera => {
        if (hasCamera) {
            try {
                scanner.start();
            } catch {
                navigate(NavigationPath.MainPage);
            }
        } else {
            navigate(NavigationPath.MainPage);
            add({
                name: 'error',
                autoHiding: 3000,
                isClosable: true,
                title: `У вас отсутствует камера`,
                renderIcon: () => <Icon className="success-red" data={CircleXmarkFill} size={18} />,
              });
        }
    });
    return () => scanner.stop();
  }, [])

  return (
    <>
      <div id="video-container">
        <video id="qr-video"></video>
      </div>
    </>
  );
};