import React from 'react';
import block from 'bem-cn-lite';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { Button, Icon, useToaster } from '@gravity-ui/uikit';

import './Profile.scss';
import {Layout} from '../../components/layout';
import { ReactComponent as LogoutIcon} from '../../assets/icons/logout.svg';
import { clearFormattedPhoneNumber, formatPhoneNumber } from '../../utils/phonenumber';
import { useDeleteUserMutation, useLazyGetUserQuery, useUpdateUserMutation } from '../../store/api/auth';
import { ChevronRight, CircleCheckFill, CircleXmarkFill, PencilToLine, TrashBin } from '@gravity-ui/icons';
import { ObjectStorage } from '../../hooks/storage';
import { useNavigate } from 'react-router-dom';
import { NavigationPath } from '../../utils/constant/navigation';
import { Link } from 'react-router-dom';
import { RemoveUserSheet } from '../../components/remove-user-sheet';
import { LogoutUserSheet } from '../../components/logout-user-sheet';
import { ChangeNameSheet } from '../../components/change-name-sheet';

const b = block('profile-page');

interface UserInfo {
  name: string,
  email: string,
  phoneNumber: string,
  whatsapp: string,
  clearPhoneNumber: string,
  telegram: {
    clearPhoneNumber: string,
    phoneNumber: string,
    username: string,
  }
}

export const ProfilePage: React.FC = () => {
  const navigate = useNavigate();
  const {add} = useToaster();

  const [getUser, {
    data: getUserResponse,
    ...getUserRequestInfo
  }] = useLazyGetUserQuery();

  const [updateUser, {
    data: updateUserResponse,
    ...updateUserRequestInfo
  }] = useUpdateUserMutation();

  const [deleteUser, deleteUserRequestInfo] = useDeleteUserMutation();

  const [openRemoveUserSheet, setOpenRemoveUserSheet] = React.useState(false);
  const handleOnRemoveUserClick = () => setOpenRemoveUserSheet(true);
  const closeRemoveUserSheet = () => setOpenRemoveUserSheet(false);

  const [openLogoutUserSheet, setOpenLogoutUserSheet] = React.useState(false);
  const handleOnLogoutUserClick = () => setOpenLogoutUserSheet(true);
  const closeLogoutUserSheet = () => setOpenLogoutUserSheet(false);

  const [openChangeNameSheet, setOpenChangeNameSheet] = React.useState(false);
  const handleOnChangeNameClick = () => setOpenChangeNameSheet(true);
  const closeChangeNameSheet = () => setOpenChangeNameSheet(false);

  const [userInfo, setUserInfo] = React.useState<UserInfo>({
    name: "",
    email: "",
    phoneNumber: "",
    clearPhoneNumber: "",
    whatsapp: "",
    telegram: {
      clearPhoneNumber: "",
      phoneNumber: "",
      username: "",
    }
  });

  const handleOnUserInfoChange = (value: {[key: string]: any}) => {
    setUserInfo({...userInfo, ...value});
  }

  const handleOnUpdateUserButtonClick = () => {

    const { clearPhoneNumber, telegram, ...body } = userInfo;
    let updateUserBody: any = {
      ...body,
      phoneNumber: clearPhoneNumber ? "+" + clearPhoneNumber : null,
      telegram: {
        phoneNumber: telegram.clearPhoneNumber 
          ? "+" + telegram.clearPhoneNumber
          : null,
        username: telegram.username
      }
    }

    updateUserBody = Object.keys(updateUserBody).reduce((prev: any, curr: string) => {
      if (curr === "telegram") {
        if (updateUserBody[curr].username || updateUserBody[curr].phoneNumber) {
          return ({...prev, [curr]: updateUserBody[curr]});
        } else {
          return prev;
        }
      } else {
        if (updateUserBody[curr]) {
          return ({...prev, [curr]: updateUserBody[curr]});
        } else {
          return prev;
        }
      }
    }, {});

    updateUser(updateUserBody);
  };

  const handleOnLogoutBtnClick = () => {
    ObjectStorage.clear();
    navigate(NavigationPath.LoginPage);
  }

  React.useEffect(() => {
    getUser();
  }, [])

  React.useEffect(() => {
    if (getUserRequestInfo.isSuccess && getUserResponse) {
      setUserInfo({
        phoneNumber: formatPhoneNumber(clearFormattedPhoneNumber(getUserResponse?.result.phoneNumber || "")),
        clearPhoneNumber: clearFormattedPhoneNumber(getUserResponse?.result.phoneNumber || ""),
        email: getUserResponse?.result.email || "",
        name: getUserResponse?.result.name || "",
        whatsapp: getUserResponse?.result.whatsapp || "",
        telegram: {
          phoneNumber: formatPhoneNumber(clearFormattedPhoneNumber(getUserResponse?.result.telegram?.phoneNumber || "")),
          clearPhoneNumber: clearFormattedPhoneNumber(getUserResponse?.result.telegram?.phoneNumber || ""),
          username: getUserResponse?.result.telegram?.username || ""
        }
      });
    }
  }, [getUserRequestInfo.isSuccess, getUserResponse])

  React.useEffect(() => {
    if (updateUserRequestInfo.isError) {
      add({
        name: 'error',
        autoHiding: 3000,
        isClosable: true,
        title: `Ошибка в запросе`,
        renderIcon: () => <Icon className="success-red" data={CircleXmarkFill} size={18} />,
        // @ts-ignore
        content: updateUserRequestInfo.error.error.message,
      });
    }
  }, [updateUserRequestInfo.isError, updateUserRequestInfo.error])

  React.useEffect(() => {
    if (deleteUserRequestInfo.isError) {
      add({
        name: 'error',
        autoHiding: 3000,
        isClosable: true,
        title: `Ошибка в запросе`,
        renderIcon: () => <Icon className="success-red" data={CircleXmarkFill} size={18} />,
        // @ts-ignore
        content: deleteUserRequestInfo.error.error.message,
      });
    }
  }, [deleteUserRequestInfo.isError, deleteUserRequestInfo.error])

  React.useEffect(() => {
    if (updateUserRequestInfo.isSuccess) {
      add({
        name: 'success',
        autoHiding: 3000,
        isClosable: true,
        title: `Изменения успешно внесены`,
        renderIcon: () => <Icon className="success-green" data={CircleCheckFill} size={18} />,
      });
    }
  }, [updateUserRequestInfo.isSuccess])

  React.useEffect(() => {
    if (deleteUserRequestInfo.isSuccess) {
      navigate(NavigationPath.SuccessDeletePage);
    }
  }, [deleteUserRequestInfo.isSuccess])

  return (
    <Layout 
      navigationBar={true}
      header={
        <Stack 
          className={b("header")}
          direction='row'
          position='relative'
          width='100%'
          alignItems='flex-start'
          justifyContent='space-between'
          sx={{
            padding: "24px 16px 16px 16px"
          }}
        >
          <Box>
            <p className='fs20-primary-bold'>
              { getUserResponse?.result.name || "Как к Вам обращаться?"}
            </p>
            
            <span 
              className='fs15-secondary-thin'
              onClick={handleOnChangeNameClick}
            >
              Редактировать данные
              <Icon data={PencilToLine} size={15} />
            </span>
          </Box>
        </Stack>
      }
      sxLayout={{
        paddingBottom: '50px'
      }}
    >
      <div className={b()}>
        <Stack width='100%' flexGrow={1}>

          <Link to={NavigationPath.ProfileContactPage} className={b('card-link')}>
            <Stack 
              p={2} 
              direction='row' 
              borderRadius={4} 
              bgcolor='#F6F8F8' 
              alignItems='center'
            >
              <Box flexGrow={1}>
                <p className='fs17-primary-bold'>
                  Контакты
                </p>
                <span className='fs13-grey-thin'>
                  Которые будет видеть нашедший
                </span>
              </Box>
              <Icon data={ChevronRight} size={28} />
            </Stack>
          </Link>
          

        </Stack>

        <Stack width='100%' spacing={2}>
          <Button view='outlined' size='xl' className={b("logout-btn")} onClick={handleOnLogoutUserClick}>
            Выйти
            <LogoutIcon />
          </Button>
          <Button view='outlined' size='xl' className={b("remove-btn")} onClick={handleOnRemoveUserClick}>
            Удалить аккаунт
            <Icon data={TrashBin} size={20} />
          </Button>
        </Stack>
        
        <Stack className={b("footer")} py={2}>
          <Link to={NavigationPath.PrivacyPolicyPage} className='fs15-secondary-thin'>
            Политика конфиденциальности
          </Link>
          {/* <Link to={""} className='fs15-secondary-thin'>
            Пользовательское соглашение
          </Link> */}
          <p className='fs15-secondary-thin'>
            Версия 0.5.0
          </p>
        </Stack>
      </div>

      <RemoveUserSheet
        onOk={deleteUser}
        open={openRemoveUserSheet}
        onClose={closeRemoveUserSheet}
      />  
      <LogoutUserSheet 
        onOk={handleOnLogoutBtnClick}
        open={openLogoutUserSheet}
        onClose={closeLogoutUserSheet}
      />
      <ChangeNameSheet 
        name={userInfo.name}
        open={openChangeNameSheet}
        onOk={() => {
          handleOnUpdateUserButtonClick();
          closeChangeNameSheet();
        }}
        onChange={(newName: string) => handleOnUserInfoChange({name: newName})}
        onClose={closeChangeNameSheet}
      />
    </Layout>
  );
};