export class ObjectStorage {

  static setValue(key: string, value: any | string) {
    localStorage.setItem(key, typeof(value) === "string" ? value : JSON.stringify(value));
  }

  static getValue<T>(key: string): T | null {
    const value = localStorage.getItem(key);
    if (value) {
      return JSON.parse(value) as T
    } else {
      return null;
    }
  }

  static clear() {
    localStorage.clear();
  }
}

export enum ObjectStorageKey {
  user = "user"
}
