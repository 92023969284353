import React from 'react';
import block from 'bem-cn-lite';

import './TagList.scss';
import { Skeleton } from '@gravity-ui/uikit';
import { Stack } from '@mui/material';

const b = block('tag-list-container');

interface TagListProps {
  tags: any[];
  isLoading?: boolean;
  renderItem: (tag: any) => React.ReactNode;
  renderEmptyList: () => React.ReactNode;
  onScroll: () => void;
}

export const TagList: React.FC<TagListProps> = ({
  tags,
  isLoading,
  onScroll,
  renderItem,
  renderEmptyList,
}) => {

  const [listLastScrollTop, setListLastScrollTop] = React.useState<number>(0);
  
  const handlerOnScrollList = async (event: any) => {
    const scrollTop = event.target.scrollTop;
    const scrollTopMax = event.target.scrollHeight - event.target.clientHeight;
    
    if (scrollTop > listLastScrollTop) {
      setListLastScrollTop(scrollTop);
    } else if (scrollTop === 0) {
			setListLastScrollTop(scrollTop);
		} else {
      return;
    }

    if (scrollTopMax === scrollTop) {
      onScroll();
    }
  }

  return (
    <Stack
      className={b()} 
      onScroll={handlerOnScrollList}
      direction='column'
      spacing={2}
    >
      {
        tags.length === 0 
        ? isLoading
          ? null
          : renderEmptyList()
        : (
          <>
            {tags.map((tag) => renderItem(tag))}
          </>
        )
      }
      {isLoading
          ? <>
            <Skeleton className={b('mark-skeleton')} />
            <Skeleton className={b('mark-skeleton')} />
            <Skeleton className={b('mark-skeleton')} />
            <Skeleton className={b('mark-skeleton')} />
          </>
          : null
      }
      
    </Stack>
  );  
}