import React from 'react';
import block from 'bem-cn-lite';
import { Icon, useToaster } from '@gravity-ui/uikit';
import { CircleXmarkFill, Plus } from "@gravity-ui/icons";
import { Stack } from '@mui/material';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { useNavigate } from 'react-router-dom';

import './Main.scss';
import {Layout} from '../../components/layout';
import { WelcomeBanner } from '../../components/welcome-banner';
import { TagCard } from '../../components/tag-card';
import { TagList } from '../../components/tag-list';
import { PrimaryButton } from '../../components/button';
import { PreviewSheet } from '../../components/preview-sheet';
import { NavigationPath } from '../../utils/constant/navigation';
import { ITag, TagStatus } from '../../store/api/tag/types/models';
import { useBindNavigator } from '../../hooks/use-navigator';
import { useLocationParams } from '../../hooks/use-params';
import { useLazyGetTagsQuery } from '../../store/api/tag';
import { useLazyGetUserQuery } from '../../store/api/auth';
import { ReactComponent as EmptyIcon  } from '../../assets/icons/empty.svg';


const b = block('main-page');

const TAGS_DEFAULT_OFFSET = 10

export const MainPage: React.FC = () => {
  // const user = ObjectStorage.getValue<IUser>(ObjectStorageKey.user);
  const navigate = useNavigate();
  const {addToNavigateBar} = useBindNavigator<any>();
  const {params} = useLocationParams<any>(window.location.search);
  const {add} = useToaster();

  const [getTags, {
    data: partTags, 
    ...getTagsRequestInfo
  }] = useLazyGetTagsQuery();

  const [getActiveTags, {
    data: activeTagsCount,
  }] = useLazyGetTagsQuery({ selectFromResult: (r) => ({...r, data: r.data?.result.totalCount }) });

  const [getUser, {
    data: getUserResponse
  }] = useLazyGetUserQuery();

  const [tags, setTags] = React.useState<ITag[]>([]);
  const [tagsRequestOffset, setTagsRequestOffset] = React.useState<number>(TAGS_DEFAULT_OFFSET);
  const [stopScrollActList, setStopScrollActList] = React.useState<boolean>(false);
  // const [dataTrigger, setDataTrigger] = React.useState("");

  const handleOnScrollActList = async () => {
    if (stopScrollActList) return;
    // setDataTrigger("scroll");
    try {
      const r = await getTags({ 
        ...params, 
        offset: tagsRequestOffset 
      })
      
      if (r.status === QueryStatus.fulfilled && r.data) {
        setTags((oldTags) => [...oldTags, ...r.data.result.tags])
        if (r.data.result.tags.length === TAGS_DEFAULT_OFFSET) {
          setTagsRequestOffset(tagsRequestOffset + TAGS_DEFAULT_OFFSET);
        } else {
          setStopScrollActList(true);
        }
      } 
    } catch {

    }
    // setDataTrigger("");
  }

  const [selectedTagId, setSelectedTagId] = React.useState<string | null>(null);
  const [openPreviewTag, setOpenPreviewTag] = React.useState(false);
  const closePreviewTag = () => {
    setOpenPreviewTag(false);
    setSelectedTagId(null);
  }
 
  const handleOnEditTag = (tagId: string) => {
    navigate(`${NavigationPath.LinkTagPage}/${tagId}`, { state: { purpose: "update" } });
  }

  const handleOnPreviewTag = (tagId: string) => {
    setSelectedTagId(tagId);
  }

  const handleOnAddTagClick = () => {
    navigate(NavigationPath.CameraPage);
  }

  React.useEffect(() => {
    setTags([]);

    getUser();
    getActiveTags({ status: TagStatus.DEPOSITED });
    getTags({}).then(r => {
      setTags((r.data?.result.tags || []))
    });

    addToNavigateBar({
      ...params
    });
  }, [])

  React.useEffect(() => {
    if (selectedTagId) {
      setOpenPreviewTag(true);
    }
  }, [selectedTagId])

  React.useEffect(() => {
    if (getTagsRequestInfo.isError) {
      add({
        name: 'error',
        autoHiding: 3000,
        isClosable: true,
        title: `Ошибка в запросе`,
        renderIcon: () => <Icon className="success-red" data={CircleXmarkFill} size={18} />,
        // @ts-ignore
        content: getTagsRequestInfo.error.error.message,
      });
    }
  }, [getTagsRequestInfo.isError, getTagsRequestInfo.error])

  return (
    <Layout 
      navigationBar={true}
      sxContent={{
        // flexGrow: "1",
        // height: "unset"
      }}
      sxLayout={{
        paddingBottom: '70px'
      }}
      // onScroll={handleOnScrollActList}
    >
      <div className={b()}>
        <WelcomeBanner name={getUserResponse?.result?.name || ""} activeTagCount={activeTagsCount || 0} />
        <h3 className='fs20-primary-bold'>
          Ваши метки
        </h3>

        <TagList
          tags={tags}
          isLoading={getTagsRequestInfo.isFetching}
          onScroll={handleOnScrollActList}
          renderEmptyList={() => 
            <Stack 
              direction='column' 
              justifyContent='center' 
              alignItems='center'
              height='100%'
            >
                <EmptyIcon />
                <p className='fs17-primary-bold'>У вас еще нет меток</p>
                <span className='fs13-grey-thin'>
                  Создайте свою первую метку, чтобы прикрепить её к важным вещам
                </span>
            </Stack>
          }
          renderItem={(tag: ITag) => (
            <TagCard
              key={tag.id}
              tag={tag}
              onEdit={() => handleOnEditTag(tag.id)}
              onPreview={() => handleOnPreviewTag(tag.id)}
            />
          )}
        />

        
      </div>
      
      <Stack
        width='100%'
        position='absolute'
        bottom={20}
        left={0}
        right={0}
        px={1.5}
      >
        <PrimaryButton size='xl' onClick={handleOnAddTagClick}>
          Добавить метку
          <Icon data={Plus} size={18} />
        </PrimaryButton>
      </Stack>

      {selectedTagId
        ? <PreviewSheet 
            tagId={selectedTagId}
            open={openPreviewTag}
            onClose={closePreviewTag}
          />
        : null
      }
    </Layout>
  );
};