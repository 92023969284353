import React from 'react';
import block from 'bem-cn-lite';
import { Box, Stack, SxProps } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

import './Layout.scss';
import { House, Person } from "@gravity-ui/icons";
import { Icon } from '@gravity-ui/uikit';
import { NavigationPath } from '../../utils/constant/navigation';
import { Logo } from '../logo';

const b = block('layout');

interface LayoutProps {
	children: React.ReactNode,
	header?: React.ReactNode,
	footer?: React.ReactNode,
	mainContentBgColor?: string,
	layoutBgColor?: string,
	navigationBar?: boolean,
	sxContent?: SxProps,
	sxLayout?: SxProps,
	onScroll?: () => void;
}

export const Layout: React.FC<LayoutProps> = ({
	header,
	footer,
	children,
	navigationBar,
	onScroll,
	mainContentBgColor = "#ffffff",
	layoutBgColor = "#f2f2f2",
	sxContent = {},
	sxLayout = {}
}) => {
	const location = useLocation();

	return (
		<Box className={b()} bgcolor={layoutBgColor} sx={sxLayout} onScroll={onScroll}>
			<Box className={b('content')} sx={sxContent}>
				{header
					?	<Box className={b('header')}>
							{header}
						</Box>
					: <Box className={b('header')}>
							<Stack 
								direction='row' 
								justifyContent='center'
								sx={{
									padding: "24px 8px 16px 8px"
								}}
							>
								<Logo />
							</Stack>
						</Box>
				}
				<Box 
					className={b('main', {"with-footer": Boolean(footer)})} 
					bgcolor={mainContentBgColor}
				>
					{children}
				</Box>
				{footer
					? <Box className={b('footer')}>
							{footer}
						</Box>
					: null
				}
				{navigationBar
					?	<Stack 
							direction='row' 
							justifyContent='center' 
							alignItems='center' 
							spacing={16}
							className={b('navigation')}
						>
							<Link 
								to={NavigationPath.MainPage} 
								className={b('nav-link', {
									select: location.pathname === NavigationPath.MainPage
								})}
							>
								<Icon data={House} size={24} />
								Главная
							</Link>
							<Link 
								to={NavigationPath.ProfilePage} 
								className={b('nav-link', {
									select: location.pathname === NavigationPath.ProfilePage
								})}
							>
								<Icon data={Person} size={24} />
								Профиль
							</Link>
						</Stack>
					: null
				}
			</Box>
		</Box>
	);
};
