import { Stack } from '@mui/material';
import './InputLabel.scss';

interface InputLabelProps {
  limit?: number;
  currentCount?: number;
  labelText: string;
  children: React.ReactNode;
}

export const InputLabel: React.FC<InputLabelProps> = ({
  children, 
  labelText,
  currentCount,
  limit
}) => (
  <div className="input-label-wrapper">
    <span className="input-label">{labelText}</span>
    {children}
    {limit && currentCount
      ? <Stack width='100%' direction='row' justifyContent='flex-end'>
          <span className="input-label">{currentCount}/{limit}</span>
        </Stack>
      : null
    }
  </div>
);
