import React from 'react';
import block from 'bem-cn-lite';
import { Box } from '@mui/material';

import logo from "../../assets/logo.png";

import './Logo.scss';


const b = block('logo');

interface LogoProps {
  width?: number | string,
  height?: number | string,
}

export const Logo: React.FC<LogoProps> = ({
  height = 24,
  width = 87
}) => (
  <Box className={b()} width={width} height={height}>
    <img src={logo} />
  </Box>
);
