export enum NavigationPath {
    MainPage = '/',
    LoginPage = '/login',
    SendCodePage = '/send-code',
    PrivacyPolicyPage = '/privacy-policy',
    ProfilePage = '/profile',
    ProfileContactPage = '/profile-contact',
    SupportPage = '/support',
    LinkTagPage = '/link-tag',
    CameraPage = '/camera',
    TagPage = '/tag',
    SuccessLinkingPage = '/success-linking',
    SuccessDeletePage = '/success-delete',
    InacitveTagPage = '/inactive-tag',
    Any = '*',
}
