import React from 'react';
import block from 'bem-cn-lite';
import './WelcomeBanner.scss';
import { Stack } from '@mui/material';
import { Label } from '@gravity-ui/uikit';

const b = block('welcome-banner');

interface WelcomeBannerProps {
  name: string,
  activeTagCount: number,
}

export const WelcomeBanner: React.FC<WelcomeBannerProps> = ({
  name,
  activeTagCount
}) => (
  <div className={b()}>
    <h1 className='fs24-primary-bold'>
      Здравствуйте, {name}
    </h1>
    <Stack 
      direction='row' 
      spacing={0.5}
      borderRadius={3}
      bgcolor='#ffffff'
      px={1}
      py={1.5}
    >
      <p className='fs15-secondary-thin'>
        Ваши активные метки
      </p>
      <Label theme='success'>
        {activeTagCount}
      </Label>
    </Stack>
  </div>
);
