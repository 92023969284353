import React from 'react';
import block from 'bem-cn-lite';
import { Button, Sheet, TextInput } from '@gravity-ui/uikit';

import './ChangeNameSheet.scss';
import { Stack } from '@mui/material';
import { PrimaryButton } from '../button';
import { InputLabel } from '../input-label';

const b = block('change-name-sheet');

interface ChangeNameSheetProps {
  open: boolean,
  name: string,
  onOk: () => void,
  onChange: (newName: string) => void,
  onClose: () => void,
}

export const ChangeNameSheet: React.FC<ChangeNameSheetProps> = ({
  open,
  name,
  onChange,
  onOk,
  onClose
}) => {

  return (
    <Sheet 
      visible={open}
      onClose={onClose}
      className={b()}
    >
      <Stack p={2} width='100%'>
        <p className='fs20-primary-bold'>
          Редактирование данных
        </p>
      </Stack>
      <Stack p={2} width='100%'>
        <InputLabel labelText='Как к вам обращаться'>
          <TextInput 
            size='xl'
            hasClear
            placeholder='Максим Калашников'
            value={name}
            onChange={(e: any) => onChange(e.target.value)}
          />
        </InputLabel>
      </Stack>
      <Stack p={2} pb={4} width='100%' spacing={2}>
        <PrimaryButton
          size='xl'
          width='max'
          onClick={onOk}
        >
          Сохранить
        </PrimaryButton>
        <Button
          size='xl'
          width='max'
          onClick={onClose}
        >
          Отменить
        </Button>
      </Stack>
    </Sheet>
  );
}
