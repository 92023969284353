export const formatPhoneNumber = (phoneNumber: string) => {
    let result = '';
    if (phoneNumber.length > 0) {
        result += `+7`;
    }
    if (phoneNumber.length > 1) {
        result += ` (${phoneNumber.slice(1, 4)}`;
    }
    if (phoneNumber.length > 4) {
        result += `) ${phoneNumber.slice(4, 7)}`;
    }
    if (phoneNumber.length > 7) {
        result += `-${phoneNumber.slice(7, 9)}`;
    }
    if (phoneNumber.length > 9) {
        result += `-${phoneNumber.slice(9, 11)}`;
    }

    return result;
};

export const isPhoneNumberValid = (phoneNumber: string) => {
    return phoneNumber.length === 11;
}

export const clearFormattedPhoneNumber = (formattedPhoneNumber: string) => {
    return formattedPhoneNumber
        .replaceAll('+', '')
        .replaceAll(' ', '')
        .replaceAll(')', '')
        .replaceAll('(', '')
        .replaceAll('-', '')
        .slice(0, 11);
};
