export interface ITag {
  id: string,
  name: string | null,
  email: string | null,
  phoneNumber: string | null,
  telegram: ITelegram | null,
  whatsapp: string | null,
  description: string | null,
  expiresAt: string,
  status: string,
  reward: number | null,
  ownerName: string | null
}

export interface ITelegram {
  username: string | null,
  phoneNumber: string | null
}

export enum TagStatus {
  DEPOSITED = "DEPOSITED",
  EXPIRED = "EXPIRED",
  STORED = "STORED"
}
