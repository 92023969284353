import React from 'react';
import block from 'bem-cn-lite';
import { Icon, Text } from '@gravity-ui/uikit';
import { Stack } from '@mui/material';

import './PreviewLink.scss';
import { ChevronRight } from '@gravity-ui/icons';

const b = block('preview-link');

interface PreviewLinkProps {
  href: string,
  label: string,
  LeftIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
}

export const PreviewLink: React.FC<PreviewLinkProps> = ({
  href,
  label,
  LeftIcon
}) => (
  <Stack 
    className={b()}
    component='a'
    href={href}
    direction='row'
    alignItems='center'
    justifyContent='space-between'
  >
    <Stack 
      direction='row'
      alignItems='center'
      spacing={1}
    > 
      <LeftIcon />
      <Text>
        {label}
      </Text>
    </Stack>
    <Icon data={ChevronRight} size={16} />
  </Stack>
);
